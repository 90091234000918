<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Presenze</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Presenze</ion-title>
        </ion-toolbar>
      </ion-header>

      <div v-if="loading" class="loading_spinner">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
      </div>
      <div v-else>
        <div
          v-if="presenze.length == 0"
          class="ion-text-center loading_spinner"
        >
          <ion-text color="danger">
            <h2>Non hai presenze associate</h2>
          </ion-text>
        </div>
        <div v-else>
          <div class="ion-padding-horizontal">
            <ion-text color="primary">
              <h5>Qui vedrai le tue ultime 50 presenze.</h5>
            </ion-text>
          </div>
          <ion-list>
            <ion-item
              v-for="presenze in presenze"
              :key="presenze.tickets_reports_id"
              @click="openDetail(presenze.tickets_reports_id)"
              button="true"
              detail
            >
              <ion-label>
                <ion-text color="primary">
                  presenze DEL
                  {{ dateFormat(presenze.tickets_reports_date) }}
                </ion-text>
                <h3>Cliente: {{ riferimentoCliente(presenze) }}</h3>
              </ion-label>
            </ion-item>
          </ion-list>
        </div>
      </div>

      <ion-fab horizontal="end" vertical="bottom" slot="fixed">
        <ion-fab-button color="success" @click="getInterventi()">
          <ion-icon :icon="refresh"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  IonText,
  IonLabel,
  IonList,
  IonItem,
  IonIcon,
  IonFab,
  IonFabButton,
} from "@ionic/vue";
import { refresh } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import apiPresenze from "../services/presenze";
import { openToast } from "../services/toast";

import moment from "moment";

export default {
  name: "ElencoPresenze",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonText,
    IonLabel,
    IonList,
    IonItem,
    IonIcon,
    IonFab,
    IonFabButton,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const presenze = ref([]);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;

    /**
     *
     * Print name and last name or customer company based on condition
     *
     */
    // const riferimentoCliente = computed(() => {
    //   return (intervento) => {
    //     //console.log(intervento);
    //     if (
    //       (intervento.customers_name != null &&
    //         intervento.customers_last_name != null) ||
    //       (intervento.customers_name != "" &&
    //         intervento.customers_last_name != "")
    //     ) {
    //       return `${intervento.customers_name} ${intervento.customers_last_name}`;
    //     } else {
    //       return `${intervento.customers_company}`;
    //     }
    //   };
    // });

    function riferimentoCliente(presenza) {
      if (presenza.customers_company) {
        return `${presenza.customers_company}`;
      } else {
        return `${presenza.customers_name} ${presenza.customers_last_name}`;
      }
    }

    /**
     *
     * Return '-' if a customer field is null
     *
     */
    function checkField(field) {
      if (field == null) {
        return " ";
      } else {
        return " - " + field;
      }
    }

    /**
     *
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     *
     */
    function dateFormat(date) {
      if (moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }

    /**
     *
     * Passing id to retrive detail in InterventoDetail page
     *
     */
    function openDetail(id: string) {
      router.push(`tab2/${id}`);
    }

    function getPresenze() {
      //console.log("getPresenze");
      loading.value = true;
      apiPresenze
        .getPresenze(userID)
        .then((response) => {
          presenze.value = response;
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la richiesta degli interventi", "danger");
          console.error(error);
        })
        .finally(() => {
          loading.value = false;
        });
    }

    onMounted(() => {
      //GET INTERVENTI DATA
      getPresenze();
    });

    return {
      loading,
      presenze,
      riferimentoCliente,
      dateFormat,
      refresh,
      openDetail,
      getPresenze,
      checkField,
    };
  },
};
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.base_text {
  font-size: 14px;
}
.pb-0 {
  padding-bottom: 0;
}

ion-item {
  font-size: 14px;
}
ion-label {
  margin: 0;
}
</style>